.ck-editor {
  .ck-toolbar {
    .ck-file-dialog-button, .ck-file-dialog-button + button + .ck-dropdown + .ck-dropdown {
      display: none;
    }
  }

  .ck-content {
    min-height: 10rem;
  }
}

div.ck.ck-balloon-panel {
  position: absolute;
  background-color: white;
}

.data-up, .data-down, .data-same {
  &::before {
    content: '';
    display: inline-flex;
    background-size: contain;
    width: 1.5rem;
    height: 1.5rem;
    margin-right: .4rem;
    position: relative;
  }
}

.btn-custom {
  color: white;
  &:hover {
    color: white;
    filter: brightness(90%);
  }
}

.data-up {
  &::before {
    background-image: url(../img/data-up.svg);
    top: .15rem;
  }
}

.data-down {
  &::before {
    background-image: url(../img/data-down.svg);
    top: .25rem;
  }
}

.data-same {
  &::before {
    background-image: url(../img/data-same.svg);
    top: .2rem;
  }
}

.large-text {
  font-size: 175%;
  font-weight: 600;
}

#dashboard-screen {
  .card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: .25rem;

    h2 {
      color: #777;
      margin-bottom: 5px;
      font-size: 1.7rem;
    }

    h3 {
      color: #555;
      margin-bottom: 5px;
    }
  }
}

.col-xs-5,
.col-sm-5,
.col-md-5,
.col-lg-5 {
  min-height: 1px;
  position: relative;
}

.col-xs-5 {
  -webkit-flex: 0 0 20%;
  flex: 0 0 20%;
  max-width: 20%;
}

@media (max-width: 573px) {
  .nav-open {
    width: 100px;
  }
}

@media (min-width: 573px) {
  html.nav-open .sidebar {
    width: 205px;
  }
}

@media (min-width: 768px) {
  .col-sm-5 {
    -webkit-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
}

@media (min-width: 992px) {
  .col-md-5 {
    -webkit-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
}

@media (min-width: 1200px) {
  .col-lg-5 {
    -webkit-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
}

.alert-danger > ul {
  margin-bottom: 0;
  padding-left: 1.8rem;
}

html.nav-open .sidebar {
  width: 220px;
}