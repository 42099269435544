#dashboard-screen .link {
  cursor: pointer;
}

.content {
  display: flex;
  flex-direction: column;

}

.recharts-wrapper {
  .dashboard-tooltip {
    position: relative;

    .dashboard-tooltip-inner {
      background-color: #333;
      border-radius: 5px;
      padding: 10px 15px;
      color: #fff;
    }

    .dashboard-tooltip-arrow {
      content: "";
      position: absolute;
      top: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
    }

    p:last-child {
      margin-bottom: 0;
    }
  }

  .recharts-tooltip-wrapper-right .dashboard-tooltip-arrow {
    border-color: transparent #333 transparent transparent;
    left: -4px;
  }

  .recharts-tooltip-wrapper-left .dashboard-tooltip-arrow {
    border-color: transparent transparent transparent #333;
    right: -10px;
  }

  .info-square {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 5px;
    &.malware-square {
      background-color: #fc614b;
    }
    &.exposed-square {
      background-color: #a4a9e7;
    }
  }
}

.recharts-wrapper > .recharts-surface {
  margin-left: -70px;
}

@media (max-width: 575px) {
  #dashboard-screen {
    text-align: center;
  }
  .col-sm-3 + .col-sm-3,
  .col-md-5 + .col-md-5 {
    margin-top: 10px;
  }
}

@media (min-width: 1601px) {
  #dashboard-screen {
    .content {
      font-size: 0.8rem;
      margin-top: 15px !important;
      padding-bottom: 0px !important;
      h3 + p {
        margin-bottom: 0px;
      }
      .card {
        margin-bottom: 13px;
        h2 {
          font-size: 1.6rem;
        }
      }
    }
  }
}

@media (min-width: 576px) and (max-width: 1600px) and (max-height: 1000px) {
  #dashboard-screen {
    font-size: 0.8rem;
    .content {
      margin-top: 10px !important;
      margin-bottom: 10px !important;
      padding-bottom: 0 !important;
      > .card {
        margin-bottom: 15px;
        padding-top: 10px !important;
      }
    }
    .card h2 {
      margin-bottom: 0px;
      font-size: 1.3rem;
    }
    .recharts-responsive-container {
      height: 325px !important;
    }
    #user-info-dashboard h3 + p {
      margin-bottom: 0;
    }
    .large-text {
      font-size: 150%;
    }
    .pb-2, .py-2 {
      padding-bottom: 0.2rem !important;
    }

    #vpn-dashboard-info h3 + p {
      margin-bottom: 0;
    }
  }

  #vulnerability-chart {
    webkit-flex: 0 0 70%;
    flex: 0 0 70%;
    max-width: 70%;
  }

  #vulnerability-info {
    -webkit-flex: 0 0 30%;
    flex: 0 0 30%;
    max-width: 30%;
    .vulnerability-percentage {
      -webkit-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
      + .vulnerability-percentage {
        border-top: 1px solid #dee2e6!important;
        padding-top: 10px;
      }
      p {
        margin-bottom: 10px;
      }
    }
  }
}

#dashboard-screen {
  .card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: .25rem;
    h2 {
      color: #777;
      margin-bottom: 5px;
      font-size: 1.7rem;
    }
    h3 {
      color: #555;
      margin-bottom: 5px;
    }
  }
}

.col-xs-5,
.col-sm-5,
.col-md-5,
.col-lg-5 {
  min-height: 1px;
  position: relative;
}

.col-xs-5 {
  -webkit-flex: 0 0 20%;
  flex: 0 0 20%;
  max-width: 20%;
}

@media (min-width: 768px) {
  .col-sm-5 {
    -webkit-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
}

@media (min-width: 992px) {
  .col-md-5 {
    -webkit-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
}

@media (min-width: 1200px) {
  .col-lg-5 {
    -webkit-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
}

.alert-danger > ul {
  margin-bottom: 0;
  padding-left: 1.8rem;
}